import { Provider as ReduxProvider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { ConfirmCancelModalProvider } from '../components/ConfirmCancelModalContext';
import { SubscriptionChange } from '../components/Product/SubscriptionChange';
import { ProvidersList } from '../components/ProvidersList';
import { PublicHomeLayout } from '../components/PublicHome/Layout';
import { UserContextProvider } from '../components/UserContext';
import config from '../config';
import { useBootIntercom } from '../hooks/useBootIntercom';
import { useInstance } from '../hooks/useInstance';
import { useTitle } from '../hooks/useTitle';
import { store } from '../store/configureStore';
import { makeTitle } from '../utils/common';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

function Bootstrap() {
  useBootIntercom(useInstance(() => ['/subscription/change']));

  return null;
}

export function Component() {
  useTitle(makeTitle('Subscription'));
  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <IntercomProvider appId={config.misc.intercomAppId} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <PublicHomeLayout>
        <div className='flex-1 min-h-0 overflow-y-auto scrollbar'>
          <SubscriptionChange />
        </div>
        <Bootstrap />
      </PublicHomeLayout>
    </ProvidersList>
  );
}
